<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text type_outside">기본정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <caption class="ir_caption">
          기본정보 표
        </caption>
        <colgroup>
          <col style="width: 192px" />
          <col />
          <col style="width: 192px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>제목 <sup>*</sup></th>
            <td colspan="3">
              <Input :value.sync="model.title" :maxLength="100" />
            </td>
          </tr>

          <tr>
            <th scope="row">상세내용</th>
            <td colspan="3" style="height: 240px">
              <Textarea style="height: 240px" :value.sync="model.contents" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import Input from "@/_approval/components/common/input/Input";
import InputAutoMoney from "@/_approval/components/common/input/InputAutoMoney";
import Search from "@/_approval/components/common/search/Search";
import Textarea from "@/_approval/components/common/textarea/Textarea";
import { mapState } from "vuex";
import LocalStorageManager from "@/LocalStorageManager";
import { assetType } from "@/_approval/const/const";
// import CommKeditor from "@/components/common/CommKeditor";
import ApiService from "@/services/ApiService";
import commDocs from "@/_approval/mixins/common/commDocs";

import { decodeHTMLEntities } from "@/utils/stringUtils";

export default {
  name: "DraftInfoBasic",
  components: {
    CommOpt,
    InputAutoMoney,
    Input,
    Search,
    Textarea,
  },
  mixins: [commDocs],
  props: {
    model: Object,
  },
  data() {
    return {
      originHandleDataList: [],
      me: {
        typeMe: true,
        empNo: "",
        empName: "",
        accountId: "",
        deptName: "",
        deptCode: "",
        actorFlag: "D",
        actorState: "1",
        apprLineFlag: "1",
      },
    };
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
  },
  watch: {},
  beforeDestroy() {
    //const summernote = $('#summernote_ex');
    // if( summernote )  summernote.summernote('destroy');
  },
  async mounted() {
    // this.createSummernote({
    //   height: 200
    // });

    const userData = LocalStorageManager.shared.getUserData();
    this.me.accountId = userData.username;
    this.me.empNo = userData.empNo;
    this.me.name = userData.empName || userData.name;
    this.me.empName = "";
    this.me.deptName = "";
    this.me.deptCode = "";

    this.model.title = _.unescape(this.model.title);
  },

  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>
